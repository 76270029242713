
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    AppLanguage: () =>
      import("@/app/modules/settings/components/AppLanguage.vue"),
    PushNotifications: () =>
      import("@/app/modules/settings/components/PushNotifications.vue")
  }
})
export default class SettingsPage extends Vue {
  //
}
